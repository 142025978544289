import { FederationRuntimePlugin } from "@module-federation/runtime/types";
import versions from "./versions.json";
const store = {};
const skipRemoteInit = [];

console.log({ versions });

const CustomPlugin = (): FederationRuntimePlugin => ({
  name: "backend-remote-control",
  beforeInit: (args) => {
    store.name = args.options.name;
    console.log({ store });
    console.log({ args });
    //addGlobalResolveSharePlugin();
    return args;
  },

  init: async (args) => {
    const stage = process.env.REACT_APP_STAGE || "dev";

    await Promise.all(
      args.options.remotes.map(async (remote) => {
        const version = versions[stage][remote.alias];
        remote.entry = `https://${remote.alias}-versioning-test.cnapp-ui-${stage}.eticloud.io/${version}/remoteEntry.js`;
        return remote;
      }),
    );

    return args;
  },
  beforeLoadShare: async (args) => {
    //@ts-ignore
    while (__FEDERATION__.__INSTANCES__.length <= 1) {
      // workaround to bug thatll be fixed in next release
      await new Promise((r) => setTimeout(r, 150));
    }
    console.log("beforeLoadShare", args);
    return args;
  },
});

const addGlobalResolveSharePlugin = () => {
  // Push custom plugin to the MF GLOBAL registry to run on every remote.
  __FEDERATION__.__GLOBAL_PLUGIN__.push({
    name: "next-override-deps-plugin",

    // Allows manual resolution of shared module requests.
    resolveShare(args) {
      const { shareScopeMap, scope, pkgName, version, GlobalFederation } = args;

      console.log({ GlobalFederation }, { pkgName });
      // The first entry on the global MF instances will always be the host.

      const host = GlobalFederation["__INSTANCES__"][0];
      console.log("addGlobalResolveSharePlugin", args);
      // Here you could add the libraries that would be overriden by the host's.
      if (!host) return args;

      // Resolver function gets called to get the requested dependencies.
      // We replace the resolver function
      // Replace the dependency with the host's to override whichever one they are using.
      args.resolver = function () {
        console.log("replacing: ", pkgName);
        shareScopeMap[scope][pkgName][version] = host.options.shared[pkgName];
        shareScopeMap[scope][pkgName][version].version = "0.0.1";
        return shareScopeMap[scope][pkgName][version];
      };
      return args;
    },
  });
};

export default CustomPlugin;
